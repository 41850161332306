import {
  SET_LOGIN_DETAILS,
  SET_OPTION_DATA,
} from "../../actions/loginDetails/types";

const initialState = {
  dealerId: 0,
  optionData: null,
};

export default function handleLoginDetailsReducer(
  loginDetailsState = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN_DETAILS:
      return {
        ...loginDetailsState,
        dealerId: payload,
      };

    case SET_OPTION_DATA:
      return {
        ...loginDetailsState,
        optionData: payload,
      };
    default:
      return loginDetailsState;
  }
}
