import {
  VIEW_DETAILS_PAGE,
  HIDE_DETAILS_PAGE,
  DIAMOND_DETAILS,
  SETTING_DETAILS,
} from "./types";

export const handleViewDetailsPage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_DETAILS_PAGE,
      payload: data,
    });
  } catch (error) {}
};

export const handleHideDetailsPage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: HIDE_DETAILS_PAGE,
    });
  } catch (error) {}
};

export const handleDiamondDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DIAMOND_DETAILS,
      payload: data,
    });
  } catch (error) {}
};

export const handleSettingDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SETTING_DETAILS,
      payload: data,
    });
  } catch (error) {}
};
