import { combineReducers } from "redux";
import headerReducer from "./header/header";
import chooseYourSettingReducer from "./chooseYourSettings/chooseYourSetting";
import previewColorReducer from "./PreviewColor/previewColor";
import loginDetailsReducer from "./loginDetails/loginDetails";
import selectYourDiamondReducer from "./selectYourDimaond/selectYourDiamond";
import { RESET_APP } from "./../actions/loginDetails/types";

const appReducer = combineReducers({
  headerReducer,
  chooseYourSettingReducer,
  previewColorReducer,
  loginDetailsReducer,
  selectYourDiamondReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    // for all keys defined in your persistConfig(s)
    window.localStorage.removeItem("persist:reducer");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
