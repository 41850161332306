import {
  VIEW_DETAILS_PAGE,
  HIDE_DETAILS_PAGE,
  DIAMOND_DETAILS,
  SETTING_DETAILS,
} from "../../actions/chooseYourSetting/types";

const initialState = {
  isViewDetails: false,
  selectedRow: null,
  diamondDetails: {},
  settingDetails: {},
};

export default function handleChooseYourSetting(
  chooseYourSetting = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: true,
        selectedRow: payload,
      };
    case HIDE_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: false,
        selectedRow: null,
      };
    case DIAMOND_DETAILS:
      return {
        ...chooseYourSetting,
        diamondDetails: payload,
      };
    case SETTING_DETAILS:
      return {
        ...chooseYourSetting,
        settingDetails: payload,
      };

    default:
      return chooseYourSetting;
  }
}
